@import 'globalStyles';

.ActivitiesList {
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__verifyLocationLink {
    font-size: 0.8rem;
    margin-top: -0.5rem;
    margin-bottom: 1.5rem;
    margin-left: 0.2rem;

    span {
      @include clickable;
      border-bottom: dashed 1px $separatorColor;
    }
  }

  &__groupIssueLink {
    text-decoration: none;
    color: #222;
    border-bottom: dashed 1px $separatorColor;
    margin-top: 1rem;
    display: inline-block;
  }

  &.Page {
    max-width: none;
  }


  @include for-phone-only {
    background-color: white;
    // padding-bottom: 24px;

    .LocationTreeSelect {
      margin-bottom: 0;
    }

    .Page__title {
      display: none;
    }

    &__items {
      margin: 0 -1rem;
    }
  }

  &__location {
    margin-bottom: 32px;
  }
}
