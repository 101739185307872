
.BookingScheduler {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
    padding: 0 50px;
    text-align: center;
    background-color: white;

    &__header {
        margin-top: 80px;
        margin-bottom: 32px;

        padding: 40px 60px;

        position: relative;

        &::after {
            content: ''; 
            position: absolute;
            left: 0px; 
            top: 0px;
            border: 50px solid transparent;
            border-left: 50px solid #d53235;
            border-top: 50px solid #d53235;
        }
    }

    &__title {
        font-size: 44px;
        line-height: 56px;
        font-weight: 600;
    }

    &__date {
        margin-top: 30px;

        font-size: 24px;
        line-height: 36px;
    }

    &__noslots {
        &-wrapper {
            border: 2px solid #F6F6F6;
            background: #FAFAFA;

            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        &-text {
            color: #2C2C2C;
            text-align: center;
            font-size: 27px;
            font-style: normal;
            font-weight: 500;
            line-height: 35px;
        }
    }

    &__slots {
        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
        }

        &-item {
            padding: 24px;
            border: #005151 solid 1px;
            color: #005151;
            background-color: #f4fbf6;
            font-size: 30px;
            line-height: 45px;

            &._booked {
                color: #d53235;
                background-color: #fbeaeb;
                border-color: #d53235;
            }

            &._unavailable {
                color: #2C2C2C;
                border-color: #2C2C2C;
                background-color: #FAFAFA;
            }
        }
    }

    &__footer {
        margin-bottom: 50px;
        margin-top: 70px;

        display: flex;
        justify-content: space-between;

        &-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 24px;
        }

        &-status {
            display: flex;
            padding-left: 25px;
            gap: 60px;

            &_item {
                position: relative;

                font-size: 20px;
                line-height: 25px;

                &::before {
                    content: '';
                    position: absolute;
                    top: 5px;
                    left: -25px;

                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: #d53235;
                }

                &._green {
                    &::before {
                        background-color: #005151;
                    }
                }
            }
        }

        &-divider {
            border-bottom: 1px solid rgba(0, 5, 20, 0.12);
        }

        &-logo {
            width: 180px !important;
            height: 50px !important;

            &.teoremaLogo {
                fill: #005151 !important;
            }

            &.glandiaLogo {
                fill: #00C758 !important;
            }

            &.gspLogo {
                fill: #0072BF !important;
            }

            &.zabotaLogo {
                fill: rgb(0, 150, 183) !important;
            }
        }

        &-qrcode-wrapper {
            width: 200px;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            gap: 12px
        }

        &-qrcode-cta {
            font-size: 20px;
            line-height: 25px;
        }

        &-qrCode {
            height: 150px;
            width: 150px;

            box-shadow: 0px 8px 24px -4px rgba(0, 5, 20, 0.08), 0px 6px 12px -6px rgba(0, 5, 20, 0.12);
        }
    }

    @media (max-width: 1440px) {
        padding: 0 32px;

        &__header {
            margin-top: 32px;
            padding: 24px 48px;

            &::after {
                border: 30px solid transparent;
                border-left: 30px solid #d53235;
                border-top: 30px solid #d53235;
            }
        }

        &__title {
            font-size: 36px;
            line-height: 48px;
        }

        &__slots {
            &-wrapper {
                gap: 16px;
            }

            &-item {
                padding: 16px;

                font-size: 24px;
                line-height: 30px;
            }
        }

        &__footer {
            margin-bottom: 30px;
            margin-top: 30px;
        }
    }

    @media (max-width: 768px) {
        padding: 0 24px;
        min-height: 100vh;
        overflow-y: auto;

        &__title {
            font-size: 28px;
            line-height: 36px;
        }

        &__date {
            margin-top: 24px;

            font-size: 16px;
            line-height: 24px;
        }

        &__noslots {
            &-text {
                font-size: 16px;
                line-height: 24px;
            }
        }

        &__slots {
            &-wrapper {
                gap: 12px;
            }

            &-item {
                padding: 16px;

                font-size: 16px;
                line-height: 24px;
            }
        }

        &__footer {
            margin-bottom: 30px;
            margin-top: 50px;

            &-qrcode-wrapper {
                display: none;
            }

            &-info {
                width: 100%;
            }

            &-status {
                gap: 40px;
    
                &_item {
                    font-size: 12px;
                    line-height: 18px;
    
                    &::before {
                        left: -15px;
    
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }
    }

    @media (max-width: 480px) {
        &__slots {
            &-wrapper {
                justify-content: space-around;
            }
        }
    }

    @media (max-height: 1000px) {
        min-height: 100vh;
        overflow-y: auto;
    }
}
