@import 'globalStyles';

.ModalV2 {
  & .MuiDialog-paper {
    width: 100%;
    max-width: 800px;
    overflow-y: visible;
    
    @include for-phone-only {
      min-height: auto;
      max-width: 100vw;
      min-width: auto;
      margin: 0 16px;
      font-size: 0.75rem;
    }
  }

  &._small {
    & .MuiDialog-paper {
      width: 410px;
      min-width: auto;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  

  }

  &__title {
    font-weight: 500;
    font-size: 24px;

    &._uppercase {
      text-transform: uppercase;
    }

  }

  &__close {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    svg {
      fill: none;
      width: 24px;
      height: 24px;
    }

    &:hover {
      fill-opacity: 100%;
    }
  }

  &__content {
    overflow-y: visible;
  }
}
