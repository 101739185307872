@import 'globalStyles';

.Logo {
  display: flex;
  &__version {
    color: #fff;
    position: absolute;
    bottom: 20px;
    left: 0;
    z-index: 1000;
  }
}