@import 'globalStyles';

.ProfileInfoForm {
  margin: 24px 0;
  max-width: 40rem;

  &__checkboxController {
    display: flex;
    margin: 16px 0;
  }

  &__checkboxLabel {
    @include capitalize;
    color: $fieldNameColor;
    margin-bottom: 0.2rem;
  }

  &__checkboxGroup {
    display: flex;
    flex-direction: row;
  }

}
